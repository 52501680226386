import { Button, Typography, Grid, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CreateForm from "../../components/viewers/CreateForm";
import GroupsIcon from '@mui/icons-material/Groups';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Create = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Stack direction="row" alignItems="center" gap={1} className="header-title">
        <GroupsIcon />
        <Typography variant="h1" sx={{ flexGrow: 1 }}>Ajouter un viewer</Typography>
        <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>Retour</Button>
      </Stack>
      <Grid>
        <Grid item md={12}>
          <CreateForm />
        </Grid>
      </Grid>
    </div>
  );
};

export default Create;