import { Card, CardHeader, CardMedia } from '@mui/material';
import { GameUpdateApiInterface } from '../../interfaces/game.interface';

interface PropsInterface {
  id: string,
  game: GameUpdateApiInterface
}

const Cover = (props: PropsInterface) => {
  const coverUrl = 'https://images.igdb.com/igdb/image/upload/t_720p/' + props.game.coverUri + '.jpg';

  return (
    <Card>
      <CardHeader title="Jaquette" />
      <CardMedia>
        <img
          srcSet={coverUrl}
          alt={props.game.title}
          loading="lazy"
          width="100%"
        />
      </CardMedia>
    </Card>
  );
}

export default Cover;