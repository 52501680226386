import * as yup from "yup";
import { useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHelperText, InputLabel, Select, MenuItem, Box, FormControl, TextField, Button, Card, CardHeader, CardContent, CardActions, Divider } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useSnackbar } from 'notistack';
import { GameEditFormInterface, GameUpdateApiInterface } from '../../interfaces/game.interface';
import { ViewerApiInterface } from '../../interfaces/twitch.interface';
import { processEdit } from "../../services/game.service";
import SaveIcon from '@mui/icons-material/Save';

interface PropsInterface {
  id: string,
  game: GameUpdateApiInterface
  viewers: ViewerApiInterface[]
}

const EditForm = (props: PropsInterface) => {
  const gameCreateSchema = yup.object().shape({
    id: yup
      .number(),
    title: yup
      .string()
      .max(150, 'Le champ ne doit pas dépasser 150 caractères')
      .required('Le champ est requis')
      .trim(),
    requestViewerId: yup
      .string()
      .max(50, 'Le champ ne doit pas dépasser 50 caractères')
      .nullable()
      .trim(),
    coopViewerId: yup
      .string()
      .max(50, 'Le champ ne doit pas dépasser 50 caractères')
      .nullable()
      .trim(),
    coverUri: yup
      .string()
      .max(50, 'Le champ ne doit pas dépasser 50 caractères')
      .required('Le champ est requis')
      .trim(),
    trailerUri: yup
      .string()
      .max(50, 'Le champ ne doit pas dépasser 50 caractères')
      .nullable()
      .trim(),
    tags: yup
      .array()
      .min(1, 'Le champ doit comporter au moins 1 tag')
      .required('Le champ est requis'),
    finishedAt: yup
      .date()
      .nullable()
      .default(null)
  });

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { handleSubmit, reset, control, formState: { errors } } = useForm<GameEditFormInterface>({
    resolver: yupResolver(gameCreateSchema),
  });

  const loadAsyncForm = useCallback(async () => {
    reset({
      title: props.game.title,
      requestViewerId: props.game.requestViewerId,
      coopViewerId: props.game.coopViewerId,
      coverUri: props.game.coverUri,
      trailerUri: props.game.trailerUri,
      tags: props.game.tags,
      finishedAt: props.game.finishedAt
    });
  }, [props, reset]);

  const submitForm = (data: GameEditFormInterface) => {
    processEdit(props.id, data)
      .then(() => {
        enqueueSnackbar('Le jeu ' + data.title + ' a été correctement modifié', { variant: 'success' });
        return navigate('/games');
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la modification du jeu', { variant: 'error' });
      });
  };

  useEffect(() => {
    loadAsyncForm();
  }, [loadAsyncForm]);

  return (
    <Card>
      <CardHeader title="Informations sur le jeu" />
      <Divider />
      <Box component="form" onSubmit={handleSubmit(submitForm)}>
        <CardContent>

          <FormControl sx={{ display: 'flex' }}>
            <Controller control={control} name={'title'} defaultValue="" render={({ field }) => (
              <TextField
                label='Titre'
                error={Boolean(errors.title)}
                helperText={errors.title ? errors.title.message : " "}
                {...field}
              />
            )}
            />
          </FormControl>

          <FormControl sx={{ display: 'flex' }}>
            <Controller control={control} name={'coverUri'} defaultValue="" render={({ field }) => (
              <TextField
                label='Lien de la jaquette'
                error={Boolean(errors.coverUri)}
                helperText={errors.coverUri ? errors.coverUri.message : " "}
                {...field}
              />
            )}
            />
          </FormControl>

          <FormControl sx={{ display: 'flex' }}>
            <Controller control={control} name={'trailerUri'} defaultValue="" render={({ field }) => (
              <TextField
                label='Lien du trailer'
                error={Boolean(errors.trailerUri)}
                helperText={errors.trailerUri ? errors.trailerUri.message : " "}
                {...field}
              />
            )}
            />
          </FormControl>

          <FormControl sx={{ display: 'flex' }} size="small" margin="normal" error={errors.tags ? true : false}>
            <InputLabel id="roles">Tags</InputLabel>
            <Controller control={control} name={'tags'} defaultValue={[]} render={({ field }) => (
              <Select
                multiple
                label='Tags'
                {...field}
              >
                {props.game.tags.map((item: string) => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}

              </Select>
            )}
            />
            <FormHelperText>{errors.tags ? errors.tags.message : " "}</FormHelperText>
          </FormControl>

          <FormControl sx={{ display: 'flex' }}>
            <Controller control={control} name={'requestViewerId'} defaultValue="" render={({ field }) => (
              <TextField
                select
                label='Game request'
                {...field}
              >
                <MenuItem key={null} value={''}>Aucun</MenuItem>
                {props.viewers.map((item: ViewerApiInterface) => (
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}

              </TextField>
            )}
            />
          </FormControl>

          <FormControl sx={{ display: 'flex' }} size="small" margin="normal">
            <Controller control={control} name={'coopViewerId'} defaultValue="" render={({ field }) => (
              <TextField
                select
                label='Coopération'
                {...field}
              >
                <MenuItem key={null} value={''}>Aucun</MenuItem>
                {props.viewers.map((item: ViewerApiInterface) => (
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}

              </TextField>
            )}
            />
          </FormControl>

          <FormControl sx={{ display: 'flex' }}>
            <Controller control={control} name={'finishedAt'} defaultValue={null} render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Terminé le"
                  inputFormat="DD/MM/YYYY"
                  {...field}
                  renderInput={(params) => <TextField
                    error={Boolean(errors.finishedAt)}
                    helperText={errors.finishedAt ? errors.finishedAt.message : " "}
                    {...params}
                  />
                  }
                />
              </LocalizationProvider>
            )}
            />
          </FormControl>

        </CardContent>
        <Divider />
        <CardActions>
          <Button variant="contained" type='submit' startIcon={<SaveIcon />}>Enregistrer</Button>
        </CardActions>
      </Box>
    </Card>
  );
}

export default EditForm;