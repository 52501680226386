import { Box, Grid } from '@mui/material';
import { GameApiInterface } from '../../interfaces/game.interface';
import ReactPlayer from 'react-player'

interface PropsInterface {
  game: GameApiInterface
}

const CurrentGame = (props: PropsInterface) => {
  const coverUrl = 'https://images.igdb.com/igdb/image/upload/t_720p/' + props.game.coverUri + '.jpg';
  const videoUrl = 'http://www.youtube.com/watch?v=' + props.game.trailerUri;

  return (
    <Grid container mt={1} spacing={5}>
      <Grid item xs={12} lg={4}>
        <Box textAlign="center">
          <img
            srcSet={coverUrl}
            alt={props.game.title}
            height={500}
            className="rounded"
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={8}>
        {props.game.trailerUri &&
          <Box className="rounded">
            <ReactPlayer
              url={videoUrl}
              loop={true}
              playing={true}
              controls={true}
              muted={true}
              width="100%"
              height={500}
            />
          </Box>
        }
      </Grid>
    </Grid>
  );
}

export default CurrentGame;