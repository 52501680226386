import { UserCreateFormInterface, UserEditFormInterface, UserPasswordFormInterface, UserCreateApiInterface, UserUpdateApiInterface, UserPasswordApiInterface } from '../interfaces/user.interface';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL;

export const getUser = (id: string) => {
  return fetch(API_URL + '/api/users/' + id + '/', {
    method: 'GET',
    headers: authHeader(),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}

export const getUsers = (pageSize: number, page: number, search: string) => {
  return fetch(API_URL + '/api/users/?offset=' + pageSize + '&page=' + (page + 1) + '&search=' + search, {
    method: 'GET',
    headers: authHeader(),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}

export const processCreate = (data: UserCreateFormInterface) => {
  let user: UserCreateApiInterface = {
    firstname: data.firstname,
    lastname: data.lastname,
    email: data.email,
    password: data.password,
    roles: data.roles,
    isActive: data.isActive
  };

  return fetch(API_URL + '/api/users/', {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(user)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}

export const processEdit = (id: string, data: UserEditFormInterface) => {
  let user: UserUpdateApiInterface = {
    firstname: data.firstname,
    lastname: data.lastname,
    email: data.email,
    roles: data.roles,
    isActive: data.isActive
  };

  return fetch(API_URL + '/api/users/' + id + '/', {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(user)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}

export const processPassword = (id: string, data: UserPasswordFormInterface) => {
  let user: UserPasswordApiInterface = {
    password: data.password
  };

  return fetch(API_URL + '/api/user/password/', {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(user)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}