import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from "react-hook-form";
import { useState, useEffect, useCallback } from 'react';
import { Box, LinearProgress, Paper, Button, Card, CardActions, FormControl, Pagination, InputBase, Grid, ImageListItem, ImageListItemBar } from '@mui/material';
import { Stack } from '@mui/system';
import { useSnackbar } from 'notistack';
import { listViewers, deleteViewer } from "../../services/twitch.service";
import { ViewerApiInterface, ViewerSearchFormInterface } from '../../interfaces/twitch.interface';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';

const PAGE_SIZE = 12;

const List = () => {
  const [tableData, setTableData] = useState([]);
  const [rowCountState, setRowCountState] = useState(0);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const gameSearchSchema = yup.object().shape({
    search: yup
      .string()
      .trim()
  });

  const { handleSubmit, control } = useForm<ViewerSearchFormInterface>({
    resolver: yupResolver(gameSearchSchema)
  });

  const fetchData = useCallback(() => {
    setIsLoading(true);
    listViewers(PAGE_SIZE, page, search)
      .then((response) => {
        setTableData(response['hydra:member']);
        setRowCountState(response['hydra:totalItems']);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la récupération des viewers', { variant: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, [page, search, enqueueSnackbar]);

  const handleDeleteViewer = useCallback((viewer: ViewerApiInterface) => {
    deleteViewer(viewer.id)
      .then(() => {
        enqueueSnackbar('Le viewer a été correctement supprimé', { variant: 'success' });
        fetchData();
      })
      .catch((error: any) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la suppression du viewer', { variant: 'error' });
      });
  }, [enqueueSnackbar, fetchData]);

  const handlePageChange = useCallback((value: number) => {
    setPage(value - 1);
  }, [setPage]);

  const handleSubmitSearchForm = useCallback((data: ViewerSearchFormInterface) => {
    setSearch(data.search);
  }, [setSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <FormControl component="form" sx={{ display: 'flex' }} onSubmit={handleSubmit(handleSubmitSearchForm)}>
        <Controller control={control} name={'search'} defaultValue="" render={({ field }) => (
          <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
            <InputBase
              placeholder='Rechercher...'
              sx={{ ml: 1, flex: 1 }}
              {...field}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSubmit(handleSubmitSearchForm)}>
              <SearchIcon />
            </IconButton>
          </Paper>
        )}
        />
      </FormControl>

      <Box sx={{ height: '20px', width: '100%' }}>
        {isLoading &&
          <LinearProgress />
        }
      </Box>

      <Grid container spacing={2}>
        {tableData.map((item: ViewerApiInterface) => (
          <Grid item xs={6} sm={4} md={3} lg={2} key={item.id}>
            <Card>
              <ImageListItem key={item.id}>
                <img
                  srcSet={`https://static-cdn.jtvnw.net/jtv_user_pictures/${item.profileUri}`}
                  alt={item.name}
                  loading="lazy"
                  width={260}
                  height={346}
                />
                <ImageListItemBar title={item.name} />
              </ImageListItem>
              <CardActions>
                <Button size="small" startIcon={<DeleteIcon />} onClick={() => handleDeleteViewer(item)}>Supprimer</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <br />
      <Stack direction="row" justifyContent="center" alignItems="center">
        {tableData.length > 0 &&
          <Pagination count={Math.ceil(rowCountState / PAGE_SIZE)} onChange={(event, page) => handlePageChange(page)} />
        }
      </Stack>
    </div>
  );
}

export default List;