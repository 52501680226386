import { useEffect, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Stack, Avatar, Typography } from '@mui/material';
import { GameApiInterface } from '../../interfaces/game.interface';
import { ViewerApiInterface } from '../../interfaces/twitch.interface';
import { getViewer } from '../../services/twitch.service';

interface PropsInterface {
  game: GameApiInterface
}

const Viewer = (props: PropsInterface) => {
  const [viewer, setViewer] = useState<ViewerApiInterface | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const loadViewer = useCallback(async () => {
    let viewerId = null;

    if (props.game.coopViewerId) {
      viewerId = props.game.coopViewerId;
    }

    if (props.game.requestViewerId) {
      viewerId = props.game.requestViewerId;
    }

    if (viewerId) {
      getViewer(viewerId)
        .then((json: any) => {
          let result = (json as ViewerApiInterface);
          setViewer(result);
        })
        .catch((error: any) => {
          console.log(error);
          enqueueSnackbar('Une erreur s\'est produite lors de la récupération du viewer', { variant: 'error' });
        });
    }
  }, [props, enqueueSnackbar]);

  useEffect(() => {
    loadViewer();
  }, [loadViewer]);

  return (
    <div>
      {viewer &&
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} id="component-viewer">
          <Avatar
            src={`https://static-cdn.jtvnw.net/jtv_user_pictures/${viewer.profileUri}`}
            alt={viewer.login}
            sx={{ width: 50, height: 50 }}
          />
          <Typography color="primary" fontSize={30}> {viewer.name} </Typography>
        </Stack>
      }
    </div>
  );
}

export default Viewer;