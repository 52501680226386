import { GameCreateFormInterface, GameEditFormInterface, GameCreateApiInterface, GameUpdateApiInterface } from '../interfaces/game.interface';
import authHeader from './auth-header';
import publicHeader from './public-header'

const API_URL = process.env.REACT_APP_API_URL;

export const getGame = (id: string) => {
  return fetch(API_URL + '/api/games/' + id + '/', {
    method: 'GET',
    headers: authHeader(),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}

export const getGameEnabled = () => {
  return fetch(API_URL + '/api/game-enabled/', {
    method: 'GET',
    headers: publicHeader(),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}

export const deleteGame = (id: string) => {
  return fetch(API_URL + '/api/games/' + id + '/', {
    method: 'DELETE',
    headers: authHeader(),
  })
    .then((response) => {
      if (response.status === 200 || response.status === 204) {
        return true;
      } else {
        return Promise.reject(response);
      }
    })
}

export const listGames = (pageSize: number, page: number, search: string) => {
  return fetch(API_URL + '/api/games/?offset=' + pageSize + '&page=' + (page + 1) + '&search=' + search, {
    method: 'GET',
    headers: authHeader(),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}

export const getRemoteGames = (search: string) => {
  return fetch(API_URL + '/api/remote-games/?search=' + search, {
    method: 'GET',
    headers: authHeader(),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}

export const getGameStatistics = () => {
  return fetch(API_URL + '/api/game-statistics/', {
    method: 'GET',
    headers: authHeader(),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}

export const processCreate = (data: GameCreateFormInterface) => {
  let game: GameCreateApiInterface = {
    remoteId: data.id.toString(),
    title: data.title,
    coverUri: data.coverUri,
    trailerUri: data.trailerUri,
    tags: data.tags
  };

  return fetch(API_URL + '/api/games/', {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(game)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}

export const processEdit = (id: string, data: GameEditFormInterface) => {
  let game: GameUpdateApiInterface = {
    remoteId: data.remoteId,
    title: data.title,
    requestViewerId: data.requestViewerId !== '' ? data.requestViewerId : null,
    coopViewerId: data.coopViewerId !== '' ? data.coopViewerId : null,
    coverUri: data.coverUri,
    trailerUri: data.trailerUri,
    tags: data.tags,
    finishedAt: data.finishedAt
  };

  return fetch(API_URL + '/api/games/' + id + '/', {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(game)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}

export const enableGame = (id: string) => {
  return fetch(API_URL + '/api/games/' + id + '/enable/', {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({})
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}