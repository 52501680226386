import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Button, Box, Typography, Tabs, Tab, Grid, Divider, Stack } from "@mui/material";
import { UserUpdateApiInterface } from '../../interfaces/user.interface';
import { getUser } from "../../services/user.service";
import EditForm from "../../components/users/EditForm";
import PasswordForm from "../../components/users/PasswordForm";
import GroupIcon from '@mui/icons-material/Group';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Edit = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState<UserUpdateApiInterface | undefined>(undefined);
  let { id } = useParams();
  let userId = String(id);

  const [activeTab, setActiveTab] = React.useState(0);

  const loadUser = useCallback(async () => {
    getUser(userId)
      .then((json: any) => {
        let result = (json as UserUpdateApiInterface);
        setUser(result);
      })
      .catch((error: any) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la récupération de l\'utilisateur', { variant: 'error' });
      });
  }, [userId, enqueueSnackbar]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  return (
    <div>
      <Stack direction="row" alignItems="center" gap={1} className="header-title">
        <GroupIcon />
        <Typography variant="h1" sx={{ flexGrow: 1 }}>Modification d'un utilisateur</Typography>
        <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>Retour</Button>
      </Stack>

      <Box sx={{ width: '100%' }}>
        <Tabs
          variant="scrollable"
          aria-label="Vertical tabs example"
          value={activeTab}
          indicatorColor="primary"
          textColor="inherit"
          onChange={handleTabChange}
        >
          <Tab label="Informations" {...a11yProps(0)} />
          <Tab label="Mot de passe" {...a11yProps(1)} />
        </Tabs>
        <Divider />
      </Box>
      {user &&
        <TabPanel value={activeTab} index={0}>
          <Grid item md={12}>
            <EditForm id={userId} user={user} />
          </Grid>
        </TabPanel>
      }
      <TabPanel value={activeTab} index={1}>
        <Grid>
          <Grid item md={12}>
            <PasswordForm id={userId} />
          </Grid>
        </Grid>
      </TabPanel>
    </div>
  );
};

export default Edit;