import { createTheme } from '@mui/material/styles';

const MainTheme = createTheme({
  typography: {
    fontFamily: 'Ubuntu',
    fontSize: 12,
    h1: {
      fontSize: '1.2rem',
      fontWeight: 500,
      lineHeight: '4',
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#fb8500',
    },
    secondary: {
      main: '#1e88e5',
    },
    background: {
      default: '#161616'
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: 'medium',
      },
    },
    MuiFilledInput: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'none',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'normal',
        size: 'small',
      },
    },
    MuiSelect: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontSize: '1rem',
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-end',
        }
      }
    },
  },
});

export default MainTheme;