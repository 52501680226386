export default function authHeader() {
  const token = localStorage.getItem('token');
  if (token) {
    return {
      'Content-Type': 'application/json',
      'Accept': 'application/ld+json',
      'Authorization': 'Bearer ' + token.replace(/^"(.*)"$/, '$1')
    };
  } else {
    return {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': ''
    };
  }
}