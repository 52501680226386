import { Box } from '@mui/material';
import { GameApiInterface } from '../../interfaces/game.interface';

interface PropsInterface {
  game: GameApiInterface
}

const Cover = (props: PropsInterface) => {
  const coverUrl = 'https://images.igdb.com/igdb/image/upload/t_720p/' + props.game.coverUri + '.jpg';

  return (
    <Box id="component-cover">
        <img
          srcSet={coverUrl}
          alt={props.game.title}
          width={450}
          height={600}
          className="rounded"
        />
    </Box>
  );
}

export default Cover;