import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import { GameStatisticsApiInterface } from '../../interfaces/game.interface';

interface PropsInterface {
    statistics: GameStatisticsApiInterface
}

const Statistics = (props: PropsInterface) => {
    return (
        <Stack direction="row" alignItems="center" justifyContent="center" gap={10}>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                    variant="determinate"
                    size={200}
                    value={((props.statistics.total - props.statistics.finished) / props.statistics.total * 100)}
                />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                        fontSize={30}
                        textAlign="center"
                    >
                        En cours 
                        <br />
                        {(props.statistics.total - props.statistics.finished)}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                    variant="determinate"
                    size={200}
                    value={(props.statistics.finished / props.statistics.total * 100)}
                />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                        fontSize={30}
                        textAlign="center"
                    >
                        Terminés
                        <br />
                        {props.statistics.finished}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                    variant="determinate"
                    size={200}
                    value={(props.statistics.requested / props.statistics.total * 100 )}
                />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                        fontSize={30}
                        textAlign="center"
                    >
                        Requests
                        <br />
                        {props.statistics.requested}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                    variant="determinate"
                    size={200}
                    value={(props.statistics.cooperated / props.statistics.total * 100 )}
                />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                        fontSize={30}
                        textAlign="center"
                    >
                        Coops
                        <br />
                        {props.statistics.cooperated}
                    </Typography>
                </Box>
            </Box>
        </Stack>
    );
}

export default Statistics;