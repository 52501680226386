import { Grid, Stack, Typography } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { getGameStatistics, getGameEnabled } from "../../services/game.service";
import { GameStatisticsApiInterface, GameApiInterface } from '../../interfaces/game.interface';
import Statistics from "../../components/home/Statistics";
import CurrentGame from "../../components/home/CurrentGame";
import HomeIcon from '@mui/icons-material/Home';

const Home = () => {
  const [gameStatistics, setGameStatistics] = useState<GameStatisticsApiInterface | undefined>(undefined);
  const [currentGame, setCurrentGame] = useState<GameApiInterface | undefined>(undefined);

  const { enqueueSnackbar } = useSnackbar();

  const fetchData = useCallback(() => {
    getGameStatistics()
      .then((response) => {
        setGameStatistics(response);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la récupération des statistiques', { variant: 'error' });
      })

    getGameEnabled()
      .then((response) => {
        setCurrentGame(response);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la récupération du jeu', { variant: 'error' });
      })
  }, [enqueueSnackbar, setGameStatistics, setCurrentGame]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <Stack direction="row" alignItems="center" gap={1} className="header-title">
        <HomeIcon />
        <Typography variant="h1" sx={{ flexGrow: 1 }}>Accueil</Typography>
      </Stack>
      <Grid>
        <Grid item>
          {gameStatistics &&
            <Statistics statistics={gameStatistics} />
          }
        </Grid>
        <Grid item>
          {currentGame &&
            <CurrentGame game={currentGame} />
          }
        </Grid>
      </Grid>
    </div>
  );
}

export default Home;