import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from "react-hook-form";
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { LinearProgress, Box, Paper, Grid, FormControl, InputBase, Button, Card, CardActions, ImageListItem, ImageListItemBar, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ViewerCreateFormInterface, ViewerSearchFormInterface } from '../../interfaces/twitch.interface';
import { getRemoteViewers, processCreate } from "../../services/twitch.service";
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const CreateForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const gameSearchSchema = yup.object().shape({
    search: yup
      .string()
      .trim()
  });

  const { handleSubmit, control } = useForm<ViewerSearchFormInterface>({
    resolver: yupResolver(gameSearchSchema)
  });

  const fetchData = useCallback(() => {
    if (search.length === 0) {
      setTableData([]);
      return false;
    }

    setIsLoading(true);
    getRemoteViewers(search)
      .then((response) => {
        setTableData(response['hydra:member']);
        return false;
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la recherche des viewers', { variant: 'error' });
      })
      .finally(() => setIsLoading(false));
  }, [search, enqueueSnackbar]);

  const handleAddClick = (data: ViewerCreateFormInterface) => {
    processCreate(data)
      .then(() => {
        enqueueSnackbar('Le viewer a été correctement ajouté', { variant: 'success' });
        return navigate('/viewers');
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de l\'ajout du viewer', { variant: 'error' });
      });
  };

  const handleSubmitSearchForm = useCallback((data: ViewerSearchFormInterface) => {
    setSearch(data.search);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <FormControl component="form" sx={{ display: 'flex' }} onSubmit={handleSubmit(handleSubmitSearchForm)}>
        <Controller control={control} name={'search'} defaultValue="" render={({ field }) => (
          <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
            <InputBase
              placeholder='Rechercher...'
              sx={{ ml: 1, flex: 1 }}
              {...field}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSubmit(handleSubmitSearchForm)}>
              <SearchIcon />
            </IconButton>
          </Paper>
        )}
        />
      </FormControl>

      <Box sx={{ height: '20px', width: '100%' }}>
        {isLoading &&
          <LinearProgress />
        }
      </Box>

      <Grid container spacing={2}>
        {tableData.map((item: ViewerCreateFormInterface) => (
          <Grid item xs={6} sm={4} md={3} lg={2} key={item.remoteId}>
            <Card>
              <ImageListItem key={item.login}>
                <img
                  srcSet={`https://static-cdn.jtvnw.net/jtv_user_pictures/${item.profileUri}`}
                  alt={item.name}
                  loading="lazy"
                  style={{
                    width: 260,
                    height: 346
                  }}
                />
                <ImageListItemBar title={item.name} />
              </ImageListItem>
              <CardActions>
                <Button size="small" startIcon={<AddCircleIcon />} onClick={() => handleAddClick(item)} disabled={isLoading}>Ajouter</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default CreateForm;