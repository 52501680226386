import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography, Grid, Stack } from "@mui/material";
import { SceneUpdateApiInterface } from '../../interfaces/scene.interface';
import { getScene, deleteScene } from "../../services/scene.service";
import EditForm from "../../components/scenes/EditForm";
import GroupIcon from '@mui/icons-material/Group';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const Edit = () => {
  let { id } = useParams();
  let sceneId = String(id);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [scene, setScene] = useState<SceneUpdateApiInterface | undefined>(undefined);
  const [open, setOpen] = useState(false);

  const loadScene = useCallback(async () => {
    getScene(sceneId)
      .then((json: any) => {
        let result = (json as SceneUpdateApiInterface);
        setScene(result);
      })
      .catch((error: any) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la récupération de la scène', { variant: 'error' });
      });
  }, [sceneId, enqueueSnackbar]);

  const processDeleteScene = useCallback(async () => {
    deleteScene(sceneId)
      .then(() => {
        enqueueSnackbar('La scène a été correctement supprimée', { variant: 'success' });
        return navigate('/scenes');
      })
      .catch((error: any) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la suppression de la scène', { variant: 'error' });
      });
  }, [sceneId, navigate, enqueueSnackbar]);

  const handleDeleteOpen = () => {
    setOpen(true);
  };

  const handleDeleteClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadScene();
  }, [loadScene]);

  return (
    <div>
      <Stack direction="row" alignItems="center" gap={1} className="header-title">
        <GroupIcon />
        <Typography variant="h1" sx={{ flexGrow: 1 }}>Modification d'une scène</Typography>
        <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>Retour</Button>
        <Button variant="contained" color="error" startIcon={<RemoveCircleIcon />} onClick={() => handleDeleteOpen()}>Supprimer</Button>
      </Stack>

        <Grid>
          <Grid item md={12}>
            { scene &&
              <EditForm id={sceneId} scene={scene} />
            }
          </Grid>
        </Grid>
      
        {scene &&
        <Dialog
          open={open}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Supprimer "{scene.title}"
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Êtes-vous certain de vouloir supprimer la scène définitivement ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose}>Annuler</Button>
            <Button onClick={processDeleteScene} color="error" autoFocus>Supprimer</Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
};

export default Edit;