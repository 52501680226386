import { ViewerCreateFormInterface, ViewerCreateApiInterface } from '../interfaces/twitch.interface';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL;

export const getViewer = (id: string) => {
  return fetch(API_URL + '/api/viewers/' + id + '/', {
    method: 'GET',
    headers: authHeader(),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}

export const deleteViewer = (id: string) => {
  return fetch(API_URL + '/api/viewers/' + id + '/', {
    method: 'DELETE',
    headers: authHeader(),
  })
    .then((response) => {
      if (response.status === 200 || response.status === 204) {
        return true;
      } else {
        return Promise.reject(response);
      }
    })
}

export const listViewers = (pageSize: number, page: number, search: string) => {
  return fetch(API_URL + '/api/viewers/?offset=' + pageSize + '&page=' + (page + 1) + '&search=' + search, {
    method: 'GET',
    headers: authHeader(),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}

export const getRemoteViewers = (search: string) => {
  return fetch(API_URL + '/api/remote-viewers/?search=' + search, {
    method: 'GET',
    headers: authHeader(),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}

export const processCreate = (data: ViewerCreateFormInterface) => {
  let game: ViewerCreateApiInterface = {
    remoteId: data.remoteId.toString(),
    name: data.name,
    login: data.login,
    profileUri: data.profileUri
  };

  return fetch(API_URL + '/api/viewers/', {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(game)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}