import { Button, Typography, Grid, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ListUsers from "../../components/users/List";
import GroupIcon from '@mui/icons-material/Group';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const List = () => {
  return (
    <div>
      <Stack direction="row" alignItems="center" gap={1} className="header-title">
        <GroupIcon />
        <Typography variant="h1" sx={{ flexGrow: 1 }}>Liste des utilisateurs</Typography>
        <Button variant="contained" component={RouterLink} startIcon={<AddCircleIcon />} to='/users/create'>Ajouter</Button>
      </Stack>
      <Grid>
        <Grid item>
          <ListUsers />
        </Grid>
      </Grid>
    </div>
  );
};

export default List;