import { AuthFormInterface, AuthUserInterface } from '../interfaces/auth.interface';
import publicHeader from './public-header'

const API_URL = process.env.REACT_APP_API_URL;

export const processLogin = (data: AuthFormInterface) => {
  return fetch(API_URL + '/authentication_token', {
    method: 'POST',
    headers: publicHeader(),
    body: JSON.stringify(data)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response.statusText);
      }
    })
    .then((json) => {
      localStorage.setItem('token', JSON.stringify(json.token));
      localStorage.setItem('user', JSON.stringify(json.user));
    })
};

export const validateToken = (): boolean => {
  const token = localStorage.getItem('token');

  if (token) {
    const decodedJwt = JSON.parse(atob(token.split(".")[1]));

    if (decodedJwt.exp * 1000 < Date.now()) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return false;
    }
    return true;
  }

  return false;
}

export const getCurrentUser = (): AuthUserInterface | undefined => {
  const currentUser = localStorage.getItem('user');

  if (currentUser !== null) {
    return JSON.parse(currentUser) as AuthUserInterface;
  }
}

export const processLogout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};