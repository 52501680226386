import { useEffect, useCallback, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { Container, Stack } from '@mui/material';
import { getScene } from '../../services/scene.service';
import { SceneApiInterface } from '../../interfaces/scene.interface';
import { getGameEnabled } from '../../services/game.service';
import { GameApiInterface } from '../../interfaces/game.interface';
import Title from '../../components/scenes/Title';
import Trailer from '../../components/scenes/Trailer';
import Cover from '../../components/scenes/Cover';
import Camera from '../../components/scenes/Camera';
import Description from '../../components/scenes/Description';
import Viewer from '../../components/scenes/Viewer';
import Game from '../../components/scenes/Game';
import AnimatedBackground from '../../assets/scenes/animated-background.mp4';

const View = () => {
  let { id } = useParams();
  let sceneId = String(id);
  const { enqueueSnackbar } = useSnackbar();
  const [game, setGame] = useState<GameApiInterface | undefined>(undefined);
  const [scene, setScene] = useState<SceneApiInterface | undefined>(undefined);

  const loadGameEnabled = useCallback(async () => {
    getGameEnabled()
      .then((json: any) => {
        let result = (json as GameApiInterface);
        setGame(result);
      })
      .catch((error: any) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la récupération du jeu', { variant: 'error' });
      });
  }, [enqueueSnackbar]);

  const loadScene = useCallback(async () => {
    getScene(sceneId)
      .then((json: any) => {
        let result = (json as SceneApiInterface);
        setScene(result);
      })
      .catch((error: any) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la récupération de la scène', { variant: 'error' });
      });
  }, [sceneId, enqueueSnackbar]);

  function hasComponentEnabled(component: string) {
    return scene?.components.some(item => component === item);
  }

  useEffect(() => {
    loadGameEnabled();
    loadScene();
  }, [loadGameEnabled, loadScene]);

  return (
    <Container id="scenes-view">
      <video autoPlay loop muted id="scenes-view-background">
        <source src={AnimatedBackground} type="video/mp4" />
      </video>

      {game && scene &&
        <div>
          {hasComponentEnabled('title') &&
            <Title game={game} scene={scene} />
          }
          {hasComponentEnabled('trailer') &&
            <Trailer game={game} />
          }
          {hasComponentEnabled('cover') &&
            <Cover game={game} />
          }
          {hasComponentEnabled('camera') &&
            <Camera game={game} />
          }
          {(hasComponentEnabled('description') || hasComponentEnabled('viewer')) &&
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} id="component-bottom">
              {hasComponentEnabled('viewer') &&
                <Viewer game={game} />
              }
              {hasComponentEnabled('description') &&
                <Description game={game} scene={scene} />
              }
            </Stack>
          }
          {hasComponentEnabled('game') &&
            <Game game={game} />
          }
        </div>
      }
    </Container>
  );
}

export default View;