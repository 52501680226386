import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from "react-hook-form";
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, LinearProgress, Paper, Button, Card, CardActions, FormControl, Pagination, InputBase, Grid, ImageListItem, ImageListItemBar } from '@mui/material';
import { Stack } from '@mui/system';
import { useSnackbar } from 'notistack';
import { listGames, enableGame } from "../../services/game.service";
import { GameApiInterface, GameSearchFormInterface } from '../../interfaces/game.interface';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PaidIcon from '@mui/icons-material/Paid';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import GroupWorkIcon from '@mui/icons-material/GroupWork';

const PAGE_SIZE = 12;

const List = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [rowCountState, setRowCountState] = useState(0);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const gameSearchSchema = yup.object().shape({
    search: yup
      .string()
      .trim()
  });

  const { handleSubmit, control } = useForm<GameSearchFormInterface>({
    resolver: yupResolver(gameSearchSchema)
  });

  const fetchData = useCallback(() => {
    setIsLoading(true);
    listGames(PAGE_SIZE, page, search)
      .then((response) => {
        setTableData(response['hydra:member']);
        setRowCountState(response['hydra:totalItems']);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la récupération des jeux', { variant: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, [page, search, enqueueSnackbar]);

  const processEnableGame = useCallback(async (game: GameApiInterface) => {
    enableGame(game.id)
      .then(() => {
        enqueueSnackbar('Le jeu a été correctement activé', { variant: 'success' });
        return navigate('/games');
      })
      .catch((error: any) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de l\'activation du jeu', { variant: 'error' });
      });
  }, [navigate, enqueueSnackbar]);

  const handlePageChange = useCallback((value: number) => {
    setPage(value - 1);
  }, []);

  const handleSubmitSearchForm = useCallback((data: GameSearchFormInterface) => {
    setSearch(data.search);
  }, []);

  const handleEditClick = useCallback((item: GameApiInterface) => {
    navigate('/games/edit/' + item.id)
  }, [navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <FormControl component="form" sx={{ display: 'flex' }} onSubmit={handleSubmit(handleSubmitSearchForm)}>
        <Controller control={control} name={'search'} defaultValue="" render={({ field }) => (
          <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
            <InputBase
              placeholder='Rechercher...'
              sx={{ ml: 1, flex: 1 }}
              {...field}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSubmit(handleSubmitSearchForm)}>
              <SearchIcon />
            </IconButton>
          </Paper>
        )}
        />
      </FormControl>

      <Box sx={{ height: '20px', width: '100%' }}>
        {isLoading &&
          <LinearProgress />
        }
      </Box>

      <Grid container spacing={2}>
        {tableData.map((item: GameApiInterface) => (
          <Grid item xs={6} sm={4} md={3} lg={2} key={item.id}>
            <Card>
              <ImageListItem key={item.id}>
                <img
                  srcSet={`https://images.igdb.com/igdb/image/upload/t_720p/${item.coverUri}.jpg`}
                  alt={item.title}
                  loading="lazy"
                  width={260}
                  height={346}
                />
                <ImageListItemBar
                  title={item.title}
                  actionIcon={
                    <Stack direction="row">
                      {item.requestViewerId &&
                        <IconButton
                          sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                          aria-label={`info about ${item.title}`}
                        >
                          <PaidIcon color="info" />
                        </IconButton>
                      }
                      {item.coopViewerId &&
                        <IconButton
                          sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                          aria-label={`info about ${item.title}`}
                        >
                          <GroupWorkIcon color="info" />
                        </IconButton>
                      }
                      {item.finishedAt &&
                        <IconButton
                          sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                          aria-label={`info about ${item.title}`}
                        >
                          <CheckCircleIcon color="success" />
                        </IconButton>
                      }
                      {item.isEnabled ?
                        <IconButton
                          sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                          aria-label={`info about ${item.title}`}
                        >
                          <PlayCircleIcon color="error" />
                        </IconButton>
                        :
                        <IconButton
                          sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                          aria-label={`info about ${item.title}`}
                          onClick={() => processEnableGame(item)}
                        >
                          <PlayCircleIcon color="inherit" />
                        </IconButton>
                      }
                    </Stack>
                  }
                />
              </ImageListItem>
              <CardActions>
                <Button size="small" startIcon={<EditIcon />} onClick={() => handleEditClick(item)}>Modifier</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <br />
      <Stack direction="row" justifyContent="center" alignItems="center">
        {tableData.length > 0 &&
          <Pagination count={Math.ceil(rowCountState / PAGE_SIZE)} onChange={(event, page) => handlePageChange(page)} />
        }
      </Stack>
    </div>
  );
}

export default List;