import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControl, TextField, Button, Card, CardHeader, CardContent, CardActions, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { UserPasswordFormInterface } from '../../interfaces/user.interface';
import { processPassword } from "../../services/user.service";
import SaveIcon from '@mui/icons-material/Save';

interface PropsInterface {
  id: string;
}

const PasswordForm = (props: PropsInterface) => {
  const userPasswordchema = yup.object().shape({
    password: yup
      .string()
      .min(8, 'Le champ doit comporter au moins 8 caractères')
      .max(32, 'Le champ ne doit pas dépasser 32 caractères')
      .required('Le champ est requis')
      .trim()
  });

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { handleSubmit, control, formState: { errors } } = useForm<UserPasswordFormInterface>({
    resolver: yupResolver(userPasswordchema),
  });

  const submitForm = (data: UserPasswordFormInterface) => {
    processPassword(props.id, data)
      .then(() => {
        enqueueSnackbar('Le mot de passe de l\'utilisateur a été correctement modifié', { variant: 'success' });
        return navigate('/users');
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la modification du mot de passe de l\'utilisateur', { variant: 'error' });
      });

    return false;
  };

  return (
    <Card>
      <CardHeader title="Mot de passe de l'utilisateur" />
      <Divider />
      <Box component="form" onSubmit={handleSubmit(submitForm)}>
        <CardContent>
          <FormControl sx={{ display: 'flex' }}>
            <Controller control={control} name={'password'} defaultValue="" render={({ field }) => (
              <TextField
                fullWidth
                type='password'
                label='Mot de passe'
                {...field}
                error={Boolean(errors.password)}
                helperText={errors.password ? errors.password.message : " "}
              />
            )}
            />
          </FormControl>
        </CardContent>
        <Divider />
        <CardActions>
          <Button variant="contained" type='submit' startIcon={<SaveIcon />}>Enregistrer</Button>
        </CardActions>
      </Box>
    </Card>
  );
}

export default PasswordForm;