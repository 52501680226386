import { Box } from '@mui/material';
import { GameApiInterface } from '../../interfaces/game.interface';
import { SceneApiInterface } from '../../interfaces/scene.interface';

interface PropsInterface {
  game: GameApiInterface,
  scene: SceneApiInterface
}

const Description = (props: PropsInterface) => {

  return (
    <Box id="component-description">
      {
        props.game.requestViewerId && !props.scene.description &&
        '[Request] '
      }
      {
        props.game.coopViewerId && !props.scene.description &&
        '[Coop] '
      }
      { props.scene.description ?
        props.scene.description :
        props.game.title
      }
    </Box>
  );
}

export default Description;