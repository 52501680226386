import { Box } from '@mui/material';
import { GameApiInterface } from '../../interfaces/game.interface';

interface PropsInterface {
  game: GameApiInterface
}

const Game = (props: PropsInterface) => {

  return (
    <Box id="component-game" />
  );
}

export default Game;