import { Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";
import MainTheme from './components/themes/MainTheme'
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from 'notistack';
import { validateToken, processLogout } from "./services/auth.service";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Navigation from './components/layout/Navigation';
import { default as UsersList } from './pages/users/List';
import { default as UsersCreate } from './pages/users/Create';
import { default as UsersEdit } from './pages/users/Edit';
import { default as GamesList } from './pages/games/List';
import { default as GamesCreate } from './pages/games/Create';
import { default as GamesEdit } from './pages/games/Edit';
import { default as ScenesList } from './pages/scenes/List';
import { default as ScenesView } from './pages/scenes/View';
import { default as ScenesCreate } from './pages/scenes/Create';
import { default as ScenesEdit } from './pages/scenes/Edit';
import { default as ViewersList } from './pages/viewers/List';
import { default as ViewersCreate } from './pages/viewers/Create';
import { default as Login } from './pages/login/Login';
import { default as Home } from './pages/home/Home';

function App() {
  const location = useLocation();

  const regexNoNavigation = new RegExp('^/login|/scenes/view/');

  const ProtectedRoute = () => {
    const isAuthenticated = validateToken();

    if (!isAuthenticated) {
      processLogout();
      return <Navigate to="/login" replace />;
    }

    return <Outlet />;
  };

  return (
    <div className="App">
      <ThemeProvider theme={MainTheme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={5}>
          <Box sx={{ display: 'flex' }}>
            { !regexNoNavigation.test(location.pathname) &&
              <Navigation />
            }
            <Box
              component="main"
              sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - 240px)` }, pt: '45px' }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Routes>
                    <Route element={<ProtectedRoute />} >
                      <Route path="/" element={<Home />} />

                      <Route path="/users" element={<UsersList />} />
                      <Route path="/users/create" element={<UsersCreate />} />
                      <Route path="/users/edit/:id" element={<UsersEdit />} />

                      <Route path="/games" element={<GamesList />} />
                      <Route path="/games/create" element={<GamesCreate />} />
                      <Route path="/games/edit/:id" element={<GamesEdit />} />

                      <Route path="/scenes" element={<ScenesList />} />
                      <Route path="/scenes/create" element={<ScenesCreate />} />
                      <Route path="/scenes/edit/:id" element={<ScenesEdit />} />

                      <Route path="/viewers" element={<ViewersList />} />
                      <Route path="/viewers/create" element={<ViewersCreate />} />
                    </Route>
                    <Route path="/login" element={<Login />} />
                    <Route path="/scenes/view/:id" element={<ScenesView />} />
                  </Routes>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
