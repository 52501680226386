import { SceneCreateFormInterface, SceneEditFormInterface, SceneCreateApiInterface, SceneUpdateApiInterface } from '../interfaces/scene.interface';
import authHeader from './auth-header';
import publicHeader from './public-header';

const API_URL = process.env.REACT_APP_API_URL;

export const getScene = (id: string) => {
  return fetch(API_URL + '/api/scenes/' + id + '/', {
    method: 'GET',
    headers: publicHeader(),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}

export const getScenes = (pageSize: number, page: number, search: string) => {
  return fetch(API_URL + '/api/scenes/?offset=' + pageSize + '&page=' + (page + 1) + '&search=' + search, {
    method: 'GET',
    headers: authHeader(),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}

export const deleteScene = (id: string) => {
  return fetch(API_URL + '/api/scenes/' + id + '/', {
    method: 'DELETE',
    headers: authHeader(),
  })
    .then((response) => {
      if (response.status === 200 || response.status === 204) {
        return true;
      } else {
        return Promise.reject(response);
      }
    })
}

export const processCreate = (data: SceneCreateFormInterface) => {
  let scene: SceneCreateApiInterface = {
    title: data.title,
    description: data.description,
    components: data.components,
  };

  return fetch(API_URL + '/api/scenes/', {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(scene)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}

export const processEdit = (id: string, data: SceneEditFormInterface) => {
  let scene: SceneUpdateApiInterface = {
    title: data.title,
    description: data.description,
    components: data.components,
  };

  return fetch(API_URL + '/api/scenes/' + id + '/', {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(scene)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
}