import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControl, Select, TextField, MenuItem, Button, Card, CardHeader, CardContent, CardActions, Divider, InputLabel, FormHelperText } from '@mui/material';
import { useSnackbar } from 'notistack';
import { SceneCreateFormInterface } from '../../interfaces/scene.interface';
import { processCreate } from "../../services/scene.service";
import SaveIcon from '@mui/icons-material/Save';

const CreateForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const sceneCreateSchema = yup.object().shape({
    title: yup
      .string()
      .max(150, 'Le champ ne doit pas dépasser 150 caractères')
      .required('Le champ est requis')
      .trim(),
    description: yup
      .string()
      .max(255, 'Le champ ne doit pas dépasser 255 caractères')
      .trim()
      .nullable(),
    components: yup
      .array()
      .min(1, 'Le champ doit comporter au moins 1 composant')
      .required('Le champ est requis'),
  });

  const { handleSubmit, control, formState: { errors } } = useForm<SceneCreateFormInterface>({
    resolver: yupResolver(sceneCreateSchema)
  });

  const submitForm = (data: SceneCreateFormInterface) => {
    processCreate(data)
      .then(() => {
        enqueueSnackbar('La scène a été correctement créée', { variant: 'success' });
        return navigate('/scenes');
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la création de la scène', { variant: 'error' });
      });

    return false;
  };

  return (
    <Card>
      <CardHeader title="Informations sur la scène" />
      <Divider />
      <Box component="form" onSubmit={handleSubmit(submitForm)}>
        <CardContent>

          <FormControl sx={{ display: 'flex' }}>
            <Controller control={control} name={'title'} defaultValue="" render={({ field }) => (
              <TextField
                label='Titre'
                error={Boolean(errors.title)}
                helperText={errors.title ? errors.title.message : " "}
                {...field}
              />
            )}
            />
          </FormControl>

          <FormControl sx={{ display: 'flex' }}>
            <Controller control={control} name={'description'} defaultValue="" render={({ field }) => (
              <TextField
                label='Description'
                error={Boolean(errors.description)}
                helperText={errors.description ? errors.description.message : " "}
                {...field}
              />
            )}
            />
          </FormControl>

          <FormControl sx={{ display: 'flex' }} size="small" margin="normal" error={errors.components ? true : false}>
            <InputLabel id="components">Components</InputLabel>
            <Controller control={control} name={'components'} defaultValue={[]} render={({ field }) => (
              <Select
                multiple
                label='Composants'
                {...field}
              >
                <MenuItem key='title' value='title'>Titre</MenuItem>
                <MenuItem key='description' value='description'>Description</MenuItem>
                <MenuItem key='game' value='game'>Jeu</MenuItem>
                <MenuItem key='cover' value='cover'>Jaquette</MenuItem>
                <MenuItem key='trailer' value='trailer'>Trailer</MenuItem>
              </Select>
            )}
            />
            <FormHelperText>{errors.components ? errors.components.message : " "}</FormHelperText>
          </FormControl>

        </CardContent>
        <Divider />
        <CardActions>
          <Button variant="contained" type='submit' startIcon={<SaveIcon />}>Enregistrer</Button>
        </CardActions>
      </Box>
    </Card>
  );
}

export default CreateForm;