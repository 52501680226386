import { Box } from '@mui/material';
import { GameApiInterface } from '../../interfaces/game.interface';
import { SceneApiInterface } from '../../interfaces/scene.interface';

interface PropsInterface {
  game: GameApiInterface,
  scene: SceneApiInterface
}

const Title = (props: PropsInterface) => {

  return (
    <Box id="component-title">
        { props.scene.title }
    </Box>
  );
}

export default Title;