import React from 'react';
import { NavLink } from "react-router-dom";
import { getCurrentUser, processLogout, validateToken } from "../../services/auth.service";
import { AppBar, Box, Toolbar, Drawer, Button, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Divider, Typography } from '@mui/material';
import { AccountCircle, Logout } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import GroupsIcon from '@mui/icons-material/Groups';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import SettingsIcon from '@mui/icons-material/Settings';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import LekeboardLogo from '../../assets/lekeboard-logo.png';

const DRAWER_WIDTH = 240;

const Navigation = () => {
  const [isAuthenticated] = React.useState(validateToken());
  const [currentUser] = React.useState(getCurrentUser());
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const regexHome = new RegExp('^/$');
  const regexGames = new RegExp('^/games');
  const regexUsers = new RegExp('^/users');
  const regexScenes = new RegExp('^/scenes');
  const regexViewers = new RegExp('^/viewers');
  const regexParameters = new RegExp('^/parameters');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    processLogout();
    window.location.reload();
  };

  const drawer = (
    <div>
      <Box sx={{ overflow: 'auto', pt: '55px' }}>
        <List dense={true}>
          <ListItem key='home' button disablePadding component={NavLink} to='/'>
            <ListItemButton selected={regexHome.test(window.location.pathname)}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary='Accueil' />
            </ListItemButton>
          </ListItem>
        </List>

        <Divider>
          <Typography color="primary">Stream</Typography>
        </Divider>

        <List dense={true}>
          <ListItem key='games' button disablePadding component={NavLink} to='/games'>
            <ListItemButton selected={regexGames.test(window.location.pathname)}>
              <ListItemIcon>
                <SportsEsportsIcon />
              </ListItemIcon>
              <ListItemText primary='Jeux' />
            </ListItemButton>
          </ListItem>
          <ListItem key='scenes' button disablePadding component={NavLink} to='/scenes'>
            <ListItemButton selected={regexScenes.test(window.location.pathname)}>
              <ListItemIcon>
                <ViewCarouselIcon />
              </ListItemIcon>
              <ListItemText primary='Scènes' />
            </ListItemButton>
          </ListItem>
          <ListItem key='viewers' button disablePadding component={NavLink} to='/viewers'>
            <ListItemButton selected={regexViewers.test(window.location.pathname)}>
              <ListItemIcon>
                <GroupsIcon />
              </ListItemIcon>
              <ListItemText primary='Viewers' />
            </ListItemButton>
          </ListItem>
        </List>

        <Divider>
          <Typography color="primary">Configuration</Typography>
        </Divider>

        <List dense={true}>
          <ListItem key='users' button disablePadding component={NavLink} to='/users'>
            <ListItemButton selected={regexUsers.test(window.location.pathname)}>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary='Utilisateurs' />
            </ListItemButton>
          </ListItem>
          <ListItem key='parameters' button disablePadding component={NavLink} to='/parameters'>
            <ListItemButton selected={regexParameters.test(window.location.pathname)}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary='Paramètres' />
            </ListItemButton>
          </ListItem>
        </List>

      </Box>
    </div>
  );

  return (
    <div>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} >
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            component="img"
            height={40}
            sx={{
              display: { md: 'flex' },
              mr: 2,
            }}
            src={LekeboardLogo}
          />
          <Box sx={{ flexGrow: 1 }}></Box>
          {isAuthenticated && currentUser && (
            <Box sx={{ flexGrow: 0 }} id="header-toolbar">
              <Button
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleMenu}
                size="large"
                startIcon={<AccountCircle />}
                color="inherit"
              >
                {currentUser.firstname} {currentUser.lastname}
              </Button>
              <Menu
                sx={{ mt: '30px' }}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Déconnexion
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Box
        id="navigation"
        component="nav"
        sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
}

export default Navigation;