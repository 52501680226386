import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from "react-hook-form";
import { useState, useEffect, useCallback } from 'react';
import { Box, LinearProgress, Chip, Typography, Dialog, Paper, Button, Card, CardActions, FormControl, Pagination, InputBase, Grid, CardContent, CardHeader } from '@mui/material';
import { Stack } from '@mui/system';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { getScenes } from "../../services/scene.service";
import { SceneApiInterface, SceneSearchFormInterface } from '../../interfaces/scene.interface';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const PAGE_SIZE = 9;

const List = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [rowCountState, setRowCountState] = useState(0);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [iframeUrl, setIframeUrl] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const sceneSearchSchema = yup.object().shape({
    search: yup
      .string()
      .trim()
  });

  const { handleSubmit, control } = useForm<SceneSearchFormInterface>({
    resolver: yupResolver(sceneSearchSchema)
  });

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  const handlePageChange = useCallback((value: number) => {
    setPage(value - 1);
  }, []);

  const handleSubmitSearchForm = useCallback((data: SceneSearchFormInterface) => {
    setSearch(data.search);
  }, []);

  const handlePreviewOpen = (scene: SceneApiInterface) => {
    setIframeUrl('/scenes/view/' + scene.id);
    setPreviewOpen(true);
  };

  const handleEditClick = useCallback((scene: SceneApiInterface) => {
    navigate('/scenes/edit/' + scene.id)
  }, [navigate]);

  const handleCopyClipboard = useCallback((scene: SceneApiInterface) => {
    navigator.clipboard.writeText(window.location.origin + '/scenes/view/' + scene.id)
    enqueueSnackbar('Le lien de la scène a été copié dans le presse papier', { variant: 'info' });
  }, [enqueueSnackbar]);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getScenes(PAGE_SIZE, page, search)
      .then((response) => {
        setTableData(response['hydra:member']);
        setRowCountState(response['hydra:totalItems']);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la récupération des scènes', { variant: 'error' });
      })
      .finally(() => setIsLoading(false));
  }, [page, search, enqueueSnackbar]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function GetComponentLabel(component: string) {
    switch (component) {
      case 'title':
        return 'Titre'
      case 'description':
        return 'Description'
      case 'viewer':
        return 'Viewer'
      case 'cover':
        return 'Jaquette'
        case 'camera':
          return 'Caméra'
      case 'game':
        return 'Jeu'
      case 'trailer':
        return 'Trailer'
    }
  }

  return (
    <div>
      <FormControl component="form" sx={{ display: 'flex' }} onSubmit={handleSubmit(handleSubmitSearchForm)}>
        <Controller control={control} name={'search'} defaultValue="" render={({ field }) => (
          <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
            <InputBase
              placeholder='Rechercher...'
              sx={{ ml: 1, flex: 1 }}
              {...field}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSubmit(handleSubmitSearchForm)}>
              <SearchIcon />
            </IconButton>
          </Paper>
        )}
        />
      </FormControl>

      <Box sx={{ height: '20px', width: '100%' }}>
        {isLoading &&
          <LinearProgress />
        }
      </Box>

      <Grid container spacing={2}>
        {tableData.map((item: SceneApiInterface) => (
          <Grid item xs={6} sm={4} md={4} lg={4} key={item.id}>
            <Card sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
              <CardHeader title={item.title} />
              <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {item.description}
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {item.components.map((component: string) => (
                    <Chip label={GetComponentLabel(component)} sx={{mr: '5px'}} /> 
                  ))}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" startIcon={<ContentCopyIcon />} onClick={() => handleCopyClipboard(item)}>Lien</Button>
                <Button size="small" startIcon={<PreviewIcon />} onClick={() => handlePreviewOpen(item)}>Preview</Button>
                <Button size="small" startIcon={<EditIcon />} onClick={() => handleEditClick(item)}>Modifier</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <br />
      <Stack direction="row" justifyContent="center" alignItems="center">
        {tableData.length > 0 &&
          <Pagination count={Math.ceil(rowCountState / PAGE_SIZE)} onChange={(event, page) => handlePageChange(page)} />
        }
      </Stack>
      <Dialog
        fullScreen
        open={previewOpen}
        onClose={handlePreviewClose}
      >
        <IconButton
          edge="start"
          color="default"
          onClick={handlePreviewClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            top: 20,
            right: 20,
          }}
        >
          <CloseIcon sx={{ fontSize: 40 }} />
        </IconButton>
        <iframe src={iframeUrl} title="game-trailer" width="100%" height="100%" />
      </Dialog>
    </div>
  );
}

export default List;