import { useEffect, useCallback, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Button, Typography, Grid, Stack, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useSnackbar } from 'notistack';
import { GameUpdateApiInterface } from '../../interfaces/game.interface';
import { getGame, deleteGame } from "../../services/game.service";
import { listViewers } from "../../services/twitch.service";
import EditForm from "../../components/games/EditForm";
import Trailer from "../../components/games/Trailer";
import Cover from "../../components/games/Cover";
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const Edit = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  let { id } = useParams();
  let gameId = String(id);
  const [game, setGame] = useState<GameUpdateApiInterface | undefined>(undefined);
  const [viewers, setViewers] = useState<[] | undefined> (undefined);
  const [open, setOpen] = useState(false);

  const loadGame = useCallback(async () => {
    getGame(gameId)
      .then((json: any) => {
        let result = (json as GameUpdateApiInterface);
        setGame(result);
      })
      .catch((error: any) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la récupération du jeu', { variant: 'error' });
      });
  }, [gameId, enqueueSnackbar]);

  const loadViewers = useCallback(async () => {
    listViewers(1000, 0, '')
      .then((json: any) => {
        setViewers(json['hydra:member']);
      })
      .catch((error: any) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la récupération des viewers', { variant: 'error' });
      });
  }, [enqueueSnackbar]);

  const processDeleteGame = useCallback(async () => {
    deleteGame(gameId)
      .then(() => {
        enqueueSnackbar('Le jeu a été correctement supprimé', { variant: 'success' });
        return navigate('/games');
      })
      .catch((error: any) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la suppression du jeu', { variant: 'error' });
      });
  }, [gameId, navigate, enqueueSnackbar]);

  const handleDeleteOpen = () => {
    setOpen(true);
  };

  const handleDeleteClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadGame();
    loadViewers();
  }, [loadGame, loadViewers]);

  return (
    <div>
      <Stack direction="row" alignItems="center" gap={1} className="header-title">
        <SportsEsportsIcon />
        <Typography variant="h1" sx={{ flexGrow: 1 }}>Modifier un jeu</Typography>
        <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>Retour</Button>
        <Button variant="contained" color="error" startIcon={<RemoveCircleIcon />} onClick={() => handleDeleteOpen()}>Supprimer</Button>
      </Stack>
      {game && viewers &&
        <Grid container spacing={2}>

          <Grid item xs={12} md={8}>
            <EditForm id={gameId} game={game} viewers={viewers} />
            <br />
            {game.trailerUri &&
              <Trailer id={gameId} game={game} />
            }
          </Grid>
          <Grid item xs={12} md={4}>
            <Cover id={gameId} game={game} />
          </Grid>
        </Grid>
      }

      {game && viewers &&
        <Dialog
          open={open}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Supprimer "{game.title}"
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Êtes-vous certain de vouloir supprimer le jeu définitivement ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose}>Annuler</Button>
            <Button onClick={processDeleteGame} color="error" autoFocus>Supprimer</Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
};

export default Edit;