import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Box, TextField, Button, FormControl, Paper } from '@mui/material';
import { useSnackbar } from 'notistack';
import { processLogin } from '../../services/auth.service'
import { AuthFormInterface } from '../../interfaces/auth.interface';
import Background from '../../assets/background.jpg';
import LekeboardLogo from '../../assets/lekeboard-logo.png';

const Login = () => {
  const { enqueueSnackbar } = useSnackbar();

  const loginSchema = yup.object().shape({
    email: yup
      .string()
      .email('Le format du champ est invalide')
      .max(180, 'Le champ ne doit pas dépasser 180 caractères')
      .required('Le champ est requis')
      .trim(),
    password: yup
      .string()
      .min(8, 'Le champ doit comporter au moins 8 caractères')
      .max(32, 'Le champ ne doit pas dépasser 32 caractères')
      .required('Le champ est requis')
      .trim(),
  });

  const { register, handleSubmit, formState: { errors } } = useForm<AuthFormInterface>({
    resolver: yupResolver(loginSchema)
  });

  const navigate = useNavigate();

  const submitForm = (data: AuthFormInterface) => {
    processLogin(data)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('L\'authentification a échouée', { variant: 'error' });
      });
  };

  return (
    <Grid container component="main" sx={{ height: '100vh', position: 'absolute', top: 0, left: 0 }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={8}
        sx={{
          backgroundImage: `url(${Background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            src={LekeboardLogo}
          />
          <Box component="form" onSubmit={handleSubmit(submitForm)} width="100%" sx={{ mt: 5 }}>
            <FormControl sx={{ display: 'flex' }}>
              <TextField
                fullWidth
                id="email"
                label='Email'
                {...register('email')}
                error={Boolean(errors.email)}
                helperText={errors.email ? errors.email.message : " "}
              />
            </FormControl>
            <FormControl sx={{ display: 'flex' }}>
              <TextField
                fullWidth
                id='password'
                type='password'
                label='Mot de passe'
                {...register('password')}
                error={Boolean(errors.password)}
                helperText={errors.password ? errors.password.message : " "}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Se Connecter
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;