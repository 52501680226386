import { Box } from '@mui/material';
import { GameApiInterface } from '../../interfaces/game.interface';
import ReactPlayer from 'react-player'

interface PropsInterface {
  game: GameApiInterface
}

const Trailer = (props: PropsInterface) => {
  const videoUrl = 'http://www.youtube.com/watch?v=' + props.game.trailerUri;

  return (
    <Box id="component-trailer">
      {props.game.trailerUri &&
        <Box className='rounded'>
          <ReactPlayer
            url={videoUrl}
            loop={true}
            playing={true}
            controls={true}
            muted={true}
            width={900}
            height={600}
          />
        </Box>
      }
    </Box>
  );
}

export default Trailer;