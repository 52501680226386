import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from "react-hook-form";
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { LinearProgress, Box, Paper, Dialog, DialogContent, DialogContentText, Grid, FormControl, InputBase, Button, Card, CardActions, ImageListItem, ImageListItemBar, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { GameCreateFormInterface, GameSearchFormInterface } from '../../interfaces/game.interface';
import { getRemoteGames, processCreate } from "../../services/game.service";
import SearchIcon from '@mui/icons-material/Search';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const CreateForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState('');
  const [iframe, setIframe] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const gameSearchSchema = yup.object().shape({
    search: yup
      .string()
      .trim()
  });

  const { handleSubmit, control } = useForm<GameSearchFormInterface>({
    resolver: yupResolver(gameSearchSchema)
  });

  const fetchData = useCallback(() => {
    if (search.length === 0) {
      setTableData([]);
      return false;
    }

    setIsLoading(true);
    getRemoteGames(search)
      .then((response) => {
        setTableData(response['hydra:member']);
        return false;
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la recherche des jeux', { variant: 'error' });
      })
      .finally(() => setIsLoading(false));
  }, [search, enqueueSnackbar]);

  const handleAddClick = (data: GameCreateFormInterface) => {
    processCreate(data)
      .then(() => {
        enqueueSnackbar('Le jeu a été correctement ajouté', { variant: 'success' });
        return navigate('/games');
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de l\'ajout du jeu', { variant: 'error' });
      });
  };

  const handlePreviewClick = (data: GameCreateFormInterface) => {
    setIframe('https://www.youtube.com/embed/' + data.trailerUri + '?autoplay=1&showinfo=0&controls=0');
    setOpen(true);
  };

  const handleSubmitSearchForm = useCallback((data: GameSearchFormInterface) => {
    setSearch(data.search);
  }, []);

  const handlePreviewClose = () => {
    setIframe('');
    setOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <FormControl component="form" sx={{ display: 'flex' }} onSubmit={handleSubmit(handleSubmitSearchForm)}>
        <Controller control={control} name={'search'} defaultValue="" render={({ field }) => (
          <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
            <InputBase
              placeholder='Rechercher...'
              sx={{ ml: 1, flex: 1 }}
              {...field}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSubmit(handleSubmitSearchForm)}>
              <SearchIcon />
            </IconButton>
          </Paper>
        )}
        />
      </FormControl>

      <Box sx={{ height: '20px', width: '100%' }}>
        {isLoading &&
          <LinearProgress />
        }
      </Box>

      <Grid container spacing={2}>
        {tableData.map((item: GameCreateFormInterface) => (
          <Grid item xs={6} sm={4} md={3} lg={2} key={item.id}>
            <Card>
              <ImageListItem key={item.coverUri}>
                <img
                  srcSet={`https://images.igdb.com/igdb/image/upload/t_720p/${item.coverUri}.jpg`}
                  alt={item.title}
                  loading="lazy"
                  style={{
                    width: 260,
                    height: 346
                  }}
                />
                <ImageListItemBar title={item.title} />
              </ImageListItem>
              <CardActions>
                {item.trailerUri &&
                  <Button size="small" startIcon={<VideoLibraryIcon />} onClick={() => handlePreviewClick(item)}>Trailer</Button>
                }
                <Button size="small" startIcon={<AddCircleIcon />} onClick={() => handleAddClick(item)} disabled={isLoading}>Ajouter</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={open}
        onClose={handlePreviewClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
      >
        <DialogContent>
          <DialogContentText id="scroll-dialog-description">
            <iframe title="trailer" width="800" height="600" src={iframe} frameBorder="0" />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CreateForm;