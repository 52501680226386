import { Box } from '@mui/material';
import { GameApiInterface } from '../../interfaces/game.interface';

interface PropsInterface {
  game: GameApiInterface
}

const Camera = (props: PropsInterface) => {

  return (
    <Box id="component-camera" />
  );
}

export default Camera;