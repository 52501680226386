import { Card, CardHeader, CardMedia } from '@mui/material';
import { GameUpdateApiInterface } from '../../interfaces/game.interface';
import ReactPlayer from 'react-player'

interface PropsInterface {
  id: string,
  game: GameUpdateApiInterface
}

const Trailer = (props: PropsInterface) => {
  const videoUrl = 'http://www.youtube.com/watch?v=' + props.game.trailerUri;

  return (
    <Card>
      <CardHeader title="Trailer" />
      <CardMedia>
        <ReactPlayer
          url={videoUrl}
          loop={false}
          playing={false}
          controls={true}
          muted={false}
          width={'100%'}
        />
      </CardMedia>
    </Card>
  );
}

export default Trailer;