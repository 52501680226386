import { Button, Typography, Grid, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ListScenes from "../../components/scenes/List";
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const List = () => {
  return (
    <div>
      <Stack direction="row" alignItems="center" gap={1} className="header-title">
        <ViewCarouselIcon />
        <Typography variant="h1" sx={{ flexGrow: 1 }}>Scènes</Typography>
        <Button variant="contained" component={RouterLink} startIcon={<AddCircleIcon />} to='/scenes/create'>Ajouter</Button>
      </Stack>
      <Grid>
        <Grid item>
          <ListScenes />
        </Grid>
      </Grid>
    </div>
  );
}

export default List;