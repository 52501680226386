import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControl, Select, TextField, MenuItem, Button, Card, CardHeader, CardContent, CardActions, Divider, InputLabel, FormHelperText } from '@mui/material';
import { useSnackbar } from 'notistack';
import { UserCreateFormInterface } from '../../interfaces/user.interface';
import { processCreate } from "../../services/user.service";
import SaveIcon from '@mui/icons-material/Save';

const CreateForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const userCreateSchema = yup.object().shape({
    email: yup
      .string()
      .email('Le format du champ est invalide')
      .max(180, 'Le champ ne doit pas dépasser 180 caractères')
      .required('Le champ est requis')
      .trim(),
    firstname: yup
      .string()
      .max(50, 'Le champ ne doit pas dépasser 50 caractères')
      .required('Le champ est requis')
      .trim(),
    lastname: yup
      .string()
      .max(50, 'Le champ ne doit pas dépasser 50 caractères')
      .required('Le champ est requis')
      .trim(),
    password: yup
      .string()
      .min(8, 'Le champ doit comporter au moins 8 caractères')
      .max(32, 'Le champ ne doit pas dépasser 32 caractères')
      .required('Le champ est requis')
      .trim(),
    roles: yup
      .array()
      .min(1, 'Le champ doit comporter au moins 1 rôle')
      .required('Le champ est requis'),
    isActive: yup
      .bool()
      .oneOf([true, false], 'La valeur du champ est invalide')
      .required('Le champ est requis'),
  });

  const { handleSubmit, control, formState: { errors } } = useForm<UserCreateFormInterface>({
    resolver: yupResolver(userCreateSchema)
  });

  const submitForm = (data: UserCreateFormInterface) => {
    processCreate(data)
      .then(() => {
        enqueueSnackbar('L\'utilisateur ' + data.firstname + ' ' + data.lastname + ' a été correctement créé', { variant: 'success' });
        return navigate('/users');
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la création de l\'utilisateur', { variant: 'error' });
      });

    return false;
  };

  return (
    <Card>
      <CardHeader title="Informations sur l'utilisateur" />
      <Divider />
      <Box component="form" onSubmit={handleSubmit(submitForm)}>
        <CardContent>

          <FormControl sx={{ display: 'flex' }}>
            <Controller control={control} name={'email'} defaultValue="" render={({ field }) => (
              <TextField
                label='Email'
                error={Boolean(errors.email)}
                helperText={errors.email ? errors.email.message : " "}
                {...field}
              />
            )}
            />
          </FormControl>

          <FormControl sx={{ display: 'flex' }}>
            <Controller control={control} name={'firstname'} defaultValue="" render={({ field }) => (
              <TextField
                label='Prénom'
                error={Boolean(errors.firstname)}
                helperText={errors.firstname ? errors.firstname.message : " "}
                {...field}
              />
            )}
            />
          </FormControl>

          <FormControl sx={{ display: 'flex' }} size="small">
            <Controller control={control} name={'lastname'} defaultValue="" render={({ field }) => (
              <TextField
                label='Nom'
                error={Boolean(errors.lastname)}
                helperText={errors.lastname ? errors.lastname.message : " "}
                {...field}
              />
            )}
            />
          </FormControl>

          <FormControl sx={{ display: 'flex' }} size="small">
            <Controller control={control} name={'password'} defaultValue="" render={({ field }) => (
              <TextField
                type='password'
                label='Mot de passe'
                error={Boolean(errors.password)}
                helperText={errors.password ? errors.password.message : " "}
                {...field}
              />
            )}
            />
          </FormControl>

          <FormControl sx={{ display: 'flex' }} size="small" margin="normal" error={errors.roles ? true : false}>
            <InputLabel id="roles">Rôles</InputLabel>
            <Controller control={control} name={'roles'} defaultValue={[]} render={({ field }) => (
              <Select
                multiple
                label='Rôles'
                {...field}
              >
                <MenuItem key='ROLE_USER' value='ROLE_USER'>Utilisateur</MenuItem>
                <MenuItem key='ROLE_SALES' value='ROLE_SALES'>Commercial</MenuItem>
                <MenuItem key='ROLE_COMMUNICATION' value='ROLE_COMMUNICATION'>Communication</MenuItem>
                <MenuItem key='ROLE_ACCOUNTING' value='ROLE_ACCOUNTING'>Finance</MenuItem>
                <MenuItem key='ROLE_ADMIN' value='ROLE_ADMIN'>Administrateur</MenuItem>
              </Select>
            )}
            />
            <FormHelperText>{errors.roles ? errors.roles.message : " "}</FormHelperText>
          </FormControl>

          <FormControl sx={{ display: 'flex' }} size="small" margin="normal" error={errors.isActive ? true : false}>
            <InputLabel id="isActive">Actif</InputLabel>
            <Controller control={control} name={'isActive'} defaultValue={true} render={({ field }) => (
              <Select
                label='Actif'
                {...field}
              >
                <MenuItem key='true' value='true'>Oui</MenuItem>
                <MenuItem key='false' value='false'>Non</MenuItem>
              </Select>
            )}
            />
            <FormHelperText>{errors.isActive ? errors.isActive.message : " "}</FormHelperText>
          </FormControl>

        </CardContent>
        <Divider />
        <CardActions>
          <Button variant="contained" type='submit' startIcon={<SaveIcon />}>Enregistrer</Button>
        </CardActions>
      </Box>
    </Card>
  );
}

export default CreateForm;