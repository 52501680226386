import { useState, useEffect, useMemo, useCallback } from 'react';
import { DataGrid, GridColumns, GridRowParams, GridActionsCellItem, GridRowId, GridFilterModel, GridToolbarQuickFilter } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { Card, Chip, Stack } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { getUsers } from "../../services/user.service";

const PAGE_SIZE = 10;

const List = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [rowCountState, setRowCountState] = useState(0);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleFilterChange = useCallback((filterModel: GridFilterModel) => {
    setSearch(filterModel.quickFilterValues?.toString() ? filterModel.quickFilterValues?.toString() : "");
  }, []);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getUsers(PAGE_SIZE, page, search)
      .then((response) => {
        setTableData(response['hydra:member']);
        setRowCountState(response['hydra:totalItems']);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Une erreur s\'est produite lors de la récupération des utilisateurs', { variant: 'error' });
      })
      .finally(() => setIsLoading(false));
  }, [page, search, enqueueSnackbar]);

  const redirectUserProfile = useCallback((userId: GridRowId) => {
    navigate('/users/edit/' + userId)
  }, [navigate]);

  const columns = useMemo<GridColumns>(() => [
    {
      field: 'id', headerName: 'ID', type: 'string', flex: 0.5, minWidth: 100, sortable: false, renderCell: (params) => (
        <Chip label={params.value} variant="outlined" color="primary" size="small" />
      )
    },
    { field: 'lastname', headerName: 'Nom', type: 'string', flex: 0.5, minWidth: 100, sortable: false },
    { field: 'firstname', headerName: 'Prénom', type: 'string', flex: 0.5, minWidth: 100, sortable: false },
    { field: 'email', headerName: 'Email', type: 'string', flex: 1, minWidth: 150, sortable: false },
    {
      field: 'roles', headerName: 'Rôles', type: 'string', flex: 1, minWidth: 100, sortable: false, renderCell: (params) => (
        <Stack direction="row" spacing={0.25}>
          {params.row.roles.map((role: string, index: number) => (
            <Chip label={GetRoleLabel(role)} key={'roles-' + index} size="small" />
          ))}
        </Stack>
      )
    },
    { field: 'isActive', headerName: 'Activé', type: 'boolean', flex: 0.2, sortable: false },
    {
      field: 'actions', type: 'actions', flex: 0.2, getActions: (params: GridRowParams) => [
        <GridActionsCellItem icon={<EditIcon color="primary" />} onClick={() => redirectUserProfile(params.id)} label="Edit" />,
      ]
    }
  ], [redirectUserProfile]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function GetRoleLabel(role: string) {
    switch (role) {
      case 'ROLE_USER':
        return 'Utilisateur'
      case 'ROLE_SALES':
        return 'Commercial'
      case 'ROLE_COMMUNICATION':
        return 'Communication'
      case 'ROLE_ACCOUNTING':
        return 'Finance'
      case 'ROLE_ADMIN':
        return 'Administrateur'
    }
  }

  function QuickSearchToolbar() {
    return (
      <Stack sx={{ ml: 2, mr: 2 }}>
        <GridToolbarQuickFilter placeholder='Rechercher...' variant='standard' fullWidth />
      </Stack>
    );
  }

  return (
    <Card>
      <DataGrid
        autoHeight
        disableColumnMenu
        rows={tableData}
        rowCount={rowCountState}
        columns={columns}
        pagination
        components={{ Toolbar: QuickSearchToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 1000 },
          },
        }}
        page={page}
        pageSize={PAGE_SIZE}
        rowsPerPageOptions={[PAGE_SIZE]}
        paginationMode="server"
        onPageChange={handlePageChange}
        filterMode="server"
        onFilterModelChange={handleFilterChange}
        disableSelectionOnClick
        loading={isLoading}
        sx={{ overflowX: 'scroll' }}
      />
    </Card>
  );
}

export default List;